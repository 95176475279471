<template>
    <div class="appmain">
        <div class="title">{{$t('personal.title')}}</div>
        <div class="list">
            <div class="item">
                <div class="lefts">
                    <div class="icon">
                        <el-image style="width:100%" :src="require('@/assets/geren/icon_nickname.png')"></el-image>
                    </div>
                    <div class="info">
                        <div class="titles">{{$t('reg.name')}}</div>
                        <div class="contents">{{info.name}}</div>
                    </div>
                </div>
                <div class="rights">
                    <div class="btn" @click="ggnicheng">{{$t('personal.change')}}</div>
                </div>
            </div>
            <div class="hengxian"></div>
            <div class="item">
                <div class="lefts">
                    <div class="icon">
                        <el-image style="width:100%" :src="require('@/assets/geren/icon_phone.png')"></el-image>
                    </div>
                    <div class="info">
                        <div class="titles">{{$t('personal.iphone')}}</div>
                        <div class="contents" v-if="info.phone">{{info.phone}}</div>
                        <div class="contents" v-if="!info.phone">{{$t('personal.notBound')}}</div>
                    </div>
                </div>
                <div class="rights">
                    <div class="btn" @click="ggshouji">
                        <span v-if="info.phone">{{$t('personal.change')}}</span>
                        <span v-if="!info.phone">{{$t('personal.toBind')}}</span>
                    </div>
                </div>
            </div>
            <div class="hengxian"></div>
            <div class="item">
                <div class="lefts">
                    <div class="icon">
                        <el-image style="width:100%" :src="require('@/assets/geren/icon_email.png')"></el-image>
                    </div>
                    <div class="info">
                        <div class="titles">{{$t('personal.email')}}</div>
                        <div class="contents" v-if="info.email">{{info.email}}</div>
                        <div class="contents" v-if="!info.email">{{$t('personal.weititle')}}</div>
                    </div>
                </div>
                <div class="rights">
                    <div class="btn" style="margin-right:10px">
                        <span v-if="!info.is_sub" @click="quxiaodingyue">{{$t('personal.dyyj')}}</span>
                        <span v-if="info.is_sub" @click="quxiaodingyue">{{$t('personal.qxdy')}}</span>
                    </div>
                    <div class="btn" @click="ggyouxiang">
                        <span v-if="info.email">{{$t('personal.change')}}</span>
                        <span v-if="!info.email">{{$t('personal.toBind')}}</span>
                    </div>
                </div>
            </div>
            <div class="hengxian"></div>
            <div class="item">
                <div class="lefts">
                    <div class="icon">
                        <el-image style="width:100%" :src="require('@/assets/geren/icon_password.png')"></el-image>
                    </div>
                    <div class="info">
                        <div class="titles">{{$t('personal.changePassword')}}</div>
                        <div class="contents">{{$t('personal.loginPassword')}}</div>
                    </div>
                </div>
                <div class="rights">
                    <div class="btn" @click="ggmima">{{$t('personal.change')}}</div>
                </div>
            </div>
            <div class="hengxian"></div>
            <div class="item">
                <div class="lefts">
                    <div class="icon">
                        <el-image style="width:100%" :src="require('@/assets/geren/icon_cancellation.png')"></el-image>
                    </div>
                    <div class="info">
                        <div class="titles">{{$t('personal.closeAnAccount')}}</div>
                        <div class="contents">{{$t('personal.deldata')}}</div>
                    </div>
                </div>
                <div class="rights">
                    <div class="btn" @click="zhuxiao">{{$t('personal.logout')}}</div>
                </div>
            </div>
            <div class="hengxian"></div>
        </div>
        <el-dialog
            :title="$t('personal.changeNickname')"
            :visible.sync="nichengDialogVisible"
            width="30%"
            center>
            <el-form ref="nichengform" :model="nichengform" label-width="60px">
                <el-form-item>
                    <el-input :placeholder="$t('reg.name')" v-model="nichengform.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="nichengDialogVisible = false" class="quxiao" >{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="nichengsubmit" class="queding">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="$t('personal.replaceAPhoneNumber')"
            :visible.sync="shoujiDialogVisible"
            width="30%"
            center>
            <el-form ref="shoujiform" :model="shoujiform" label-width="60px">
                <el-form-item>
                    <el-input :placeholder="$t('personal.iphone')" v-model="shoujiform.phone"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input :placeholder="$t('personal.codeinput')" v-model="shoujiform.code">
                        <template slot="append">
                            <span style="cursor:pointer" v-if="shoujiopen" @click="getPhoneCode">{{text}}</span>
                            <span v-if="!shoujiopen">{{shoujidaojishi}}s</span>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="shoujiDialogVisible = false" class="quxiao">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="shoujisubmit" class="queding">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="$t('personal.replaceTheEmail')"
            :visible.sync="youxiangDialogVisible"
            width="30%"
            center>
            <el-form ref="shoujiform" :model="youxiangform" label-width="60px">
                <el-form-item>
                    <el-input :placeholder="$t('personal.emailinput')" v-model="youxiangform.email"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input :placeholder="$t('personal.codeinput')" v-model="youxiangform.code">
                        <template slot="append">
                            <span style="cursor:pointer" v-if="emailopen" @click="getEmailCode">{{text}}</span>
                            <span v-if="!emailopen">{{emaildaojishi}}s</span>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="youxiangDialogVisible = false" class="quxiao">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="submiteamil" class="queding">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="$t('personal.changePassword')"
            :visible.sync="mimaDialogVisible"
            width="30%"
            center>
            <el-form :rules="rules" ref="mimaform" :model="mimaform" label-width="60px">
                <el-form-item prop="password">
                    <el-input type="password" :placeholder="$t('personal.oldpassword')"  v-model="mimaform.oldpassword"></el-input>
                </el-form-item>
                <el-form-item prop="newpassword">
                    <el-input type="password" :placeholder="$t('personal.newpassword')" v-model="mimaform.newpassword"></el-input>
                </el-form-item>
                <el-form-item prop="cnewpassword">
                    <el-input type="password" :placeholder="$t('personal.confirmpassword')" v-model="mimaform.cnewpassword"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="mimaDialogVisible = false" class="quxiao">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="submitmima" class="queding">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="$t('personal.logout')"
            :visible.sync="zhuxiaoDialogVisible"
            width="30%"
            center>
            <el-form ref="zhuxiaoform" :model="zhuxiaoform" label-width="60px">
                <el-form-item>
                    <el-input :placeholder="$t('login.userName')" v-model="zhuxiaoform.username"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input :placeholder="$t('personal.codeinput')" v-model="zhuxiaoform.code">
                        <template slot="append">
                            <span style="cursor:pointer" v-if="zhuxiaoopen" @click="getZhuxiaoCode">{{text}}</span>
                            <span v-if="!zhuxiaoopen">{{zhuxiaodaojishi}}s</span>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="zhuxiaoDialogVisible = false" class="quxiao">{{$t('cancel')}}</el-button>
                <el-button type="primary" @click="submitzhuxiao" class="queding">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getuserinfo,userUpdate,updateEmail,getCode,changePassword,logout } from "@/api/api";
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        this.title = this.$t('personal.title')+'-'+this.$t('seo.title')
        this.getInfo()
    },
    data(){
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('user.reenter')))
                // password 是表单上绑定的字段
            } else if (value !== this.mimaform.newpassword) {
                callback(new Error(this.$t('user.pwdb')))
            } else {
                callback()
            }
        }
        return{
            user_id:'',
            info:{},
            nichengDialogVisible:false,
            nichengform:{name:''},
            shoujiDialogVisible:false,
            shoujiform:{},
            youxiangDialogVisible:false,
            youxiangform:{},
            mimaDialogVisible:false,
            mimaform:{},
            zhuxiaoDialogVisible:false,
            zhuxiaoform:{},
            shoujiopen:true,
            text:this.$t('getCode'),
            shoujidaojishi:59,
            emailopen:true,
            emaildaojishi:59,
            zhuxiaoopen:true,
            zhuxiaodaojishi:59,
            rules: {
                oldpassword: [
                    { required: true, message: this.$t('personal.oldpassword'), trigger: "blur" }
                ],
                newpassword: [
                    { required: true, message: this.$t('login.pwdmsg'), trigger: "blur" },
                    { min: 5, max: 20, message: this.$t('user.mimamsg'), trigger: 'blur' }
                ],
                cnewpassword:[
                    { required: true, validator: validatePass2, trigger: 'blur' }
                ]
            },
            title:''
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    methods:{
        async getInfo(){
            let userinfo = sessionStorage.getItem('userinfo')
            userinfo = JSON.parse(userinfo)
            let token = userinfo.utoken
            this.user_id = userinfo.id
            const {data:res} = await getuserinfo({token:token})
            console.log(userinfo);
            this.info = res
        },
        ggnicheng(){
            this.nichengform.name = this.info.name
            this.nichengDialogVisible = true
        },
        async nichengsubmit(){
            let data = {
                id:this.user_id,
                name:this.nichengform.name
            }
            const {data:res} = await userUpdate(data)
            this.$message.success(this.$t('personal.modifySuccessfully'));
            this.nichengDialogVisible = false
            this.getInfo()
        },
        ggshouji(){
            this.shoujiDialogVisible = true
        },
        async shoujisubmit(){
            let data ={
                user_id:this.user_id,
                username:this.shoujiform.phone,
                code:this.shoujiform.code
            }
            const{data:res} = await updateEmail(data)
            this.$message.success(this.$t('personal.modifySuccessfully'));
            this.shoujiDialogVisible = false
            this.getInfo()
        },
        async quxiaodingyue(){
            let data = {
                id:this.user_id,
                is_sub:!this.info.is_sub
            }
            const {data:res} = await userUpdate(data)
            this.$message.success(this.$t('personal.modifySuccessfully'));
            this.getInfo()
        },
        ggyouxiang(){
            this.youxiangDialogVisible = true
        },
        async submiteamil(){
            let data ={
                user_id:this.user_id,
                username:this.youxiangform.email,
                code:this.youxiangform.code
            }
            const{data:res} = await updateEmail(data)
            this.$message.success(this.$t('personal.modifySuccessfully'));
            this.youxiangDialogVisible = false
            this.getInfo()
        },
        ggmima(){
            this.mimaDialogVisible = true
            this.mimaform = {}
        },
        async submitmima(){
            let data ={
                user_id:this.user_id,
                oldpassword:this.mimaform.oldpassword,
                newpassword:this.mimaform.newpassword
            }
            this.$refs["mimaform"].validate(valid => {
                if (valid) {
                    changePassword(data).then(res=>{
                        this.$message.success(this.$t('personal.modifySuccessfully'));
                        this.mimaDialogVisible = false
                    })
                }
            });
        },
        zhuxiao(){
            this.zhuxiaoDialogVisible = true
        },
        submitzhuxiao(){
            let data ={
                username:this.zhuxiaoform.username,
                code:this.zhuxiaoform.code
            }
            logout(data).then(res=>{
                sessionStorage.removeItem('userinfo')
                this.$router.push('/index')
            })
        },
        async getPhoneCode(){
            let value = this.shoujiform.phone
            const iphoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
            if(iphoneReg.test(value)){
                this.shoujiopen = false
                let data = {
                    username:this.shoujiform.phone
                }
                this.timer = setInterval(()=>{
                    this.shoujidaojishi--
                    if(this.daojishi===0){
                        this.shoujidaojishi = 60
                        this.shoujiopen = true
                        clearInterval(this.timer)
                    }
                },1000)
                const {data:res} = await getCode(data)
            }else{
                this.$message.error(this.$t('personal.iphonemsg'));
            }
        },
        async getEmailCode(){
            let value = this.youxiangform.email
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            if(mailReg.test(value)){
                this.emailopen = false
                let data = {
                    username:this.youxiangform.email
                }
                this.timer = setInterval(()=>{
                    this.emaildaojishi--
                    if(this.emaildaojishi===0){
                        this.emaildaojishi = 60
                        this.emailopen = true
                        clearInterval(this.timer)
                    }
                },1000)
                const {data:res} = await getCode(data)
            }else{
                this.$message.error(this.$t('personal.emailmsg'));
            }
        },
        async getZhuxiaoCode(){
            let value = this.zhuxiaoform.username
            const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
            const iphoneReg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
            if(mailReg.test(value) || iphoneReg.test(value)){
                this.zhuxiaoopen = false
                let data = {
                    username:this.zhuxiaoform.username
                }
                this.timer = setInterval(()=>{
                    this.zhuxiaodaojishi--
                    if(this.zhuxiaodaojishi===0){
                        this.zhuxiaodaojishi = 60
                        this.zhuxiaoopen = true
                        clearInterval(this.timer)
                    }
                },1000)
                const {data:res} = await getCode(data)
            }else{
                this.$message.error(this.$t('reg.emtail'));
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 480px) {
        .appmain{
            width: 90%;
        }
    }
    .appmain{
        width: 50%;
        margin: 0 auto;
        margin-top: 3%;
    }
    .title{
        text-align: center;
        font-size: 30px;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
    }
    .quxiao{
        width: 200px;
        height: 48px;
        border: 1px solid #0052D9;
        color: #0052D9;
    }
    .queding{
        width: 200px;
        height: 48px;
        border: 1px solid #0052D9;
        background: #0052D9;
    }
    .list{
        margin-bottom: 100px;
        .item{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .icon{
                width: 50px;
                height: 50px;
            }
            .lefts{
                display: flex;
                .info{
                    margin-left: 20px;
                    line-height: 28px;
                    .titles{
                        font-size: 18px;
                        font-family: SourceHanSansCN-Medium;
                        font-weight: 500;
                        color: #333333;
                    }
                    .contents{
                        font-size: 16px;
                        font-family: SourceHanSansCN-Regular;
                        font-weight: 400;
                        color: rgba(51, 51, 51, 0.8);
                    }
                }
            }
            .rights{
                display: flex;
                .btn{
                    width: 120px;
                    height: 44px;
                    margin-top: 5px;
                    background: #F2F2F1;
                    text-align: center;
                    line-height: 44px;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Regular;
                    font-weight: 400;
                    color: #0052D9;
                    cursor: pointer;
                }
            }
        }
        .hengxian{
            margin-left: 6%;
            margin-top: 10px;
            height: 1px;
            background: rgba(0, 0, 0, 0.09);
        }
    }
    
</style>
<style scoped>
    /deep/ .el-input{
        width: 90%;
    }
    /deep/ .el-button--default:focus{
        background-color: transparent;
    }
    /deep/ .el-button--default:hover{
        background-color: transparent;
    }
</style>